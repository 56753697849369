export default {
  "appbar": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "portfolioGr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI"])},
    "portfolioUi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infographie"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  },
  "hero": {
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "wedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On fait"])},
    "wedoOptions": [
      {
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design web"])}
      },
      {
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation web"])}
      },
      {
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infographie"])}
      },
      {
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX et SEO"])}
      }
    ]
  },
  "about": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CE QUE VOUS DEVEZ SAVOIR SUR MOI !"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de moi"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je détiens un certificat d'études collégiales en « technologie web (frontend) », un diplôme d'études collégiales en « programmation d'applications informatiques » et un diplôme professionnel en infographie (Infographie). J'ai développé une bonne expérience dans le domaine de l'informatique. J'ai plus de 10 ans d'expériences notamment dans la programmation, en tant que développeur Front-end et développeur UI/UX dans les services financiers, le e-commerce et le gouvernement. Depuis 2010, je travaille en tant que développeur web, développeur Front-end, développeur UI/UX, concepteur Web ou développeur Full-stack. Certains projets sur lesquels j'ai travaillé sont Pages Jaunes, Desjardins, Le Directeur général des élections du Québec (DGEQ), CGI, Air Canada, etc."])}
  },
  "services": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CE QUE VOUS DEVEZ SAVOIR SUR MOI !"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de moi"])},
    "ui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design web (UI)"])},
    "uiDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web design description"])},
    "programming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programation"])},
    "programmingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming description"])},
    "ux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User experience"])},
    "uxDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX, SEO description"])},
    "graphic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grasphic design"])},
    "graphicDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design description"])}
  },
  "portfolio": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See some projects and designs"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "gotoGraphicPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our Graphic design Porfolio page"])},
    "gotoUiPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our Web design Porfolio page"])},
    "graphicSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design"])},
    "uiSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web design (UI)"])},
    "uiSections": {
      "massage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
      "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
      "advocate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avocat"])},
      "medic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médicaments et thérapeutes"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidences"])},
      "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnelle"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
      "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petites entreprises"])},
      "kindergarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garderie"])},
      "beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon de beauté"])}
    }
  },
  "packages": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir quelques projets et conceptions"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petite entreprise"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business level"])},
    "price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), " $ et plus"])},
    "packages_note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après 14 jours après la dernière mise à jour officielle du site Web."])},
    "packages_note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans les forfaits de base et de démarrage, un an d'hébergement Web est inclus, mais les codes de privilège et d'accès seront conservés pour la société Webb de Montréal."])},
    "includedService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services incluses"])},
    "packages_note3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pré-conception personnalisée complète et conception graphique finale en couleur, conception d'interface utilisateur haut de gamme, codage et développement personnalisés, multilingue, formulaire de contact dynamique personnalisé, création de base de données complète, intégration, soumission au moteur de recherche"])},
    "includes": {
      "pageCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Site Web avec ", _interpolate(_named("page")), " pages et plusieurs sections"])},
      "contactForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact form"])},
      "revisionDesign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("no")), " Revision"])},
      "responsive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsive design"])},
      "seo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEO friendly"])},
      "optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation des images"])},
      "satisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction garantie"])},
      "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an d'hébergement gratuit"])},
      "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site sécurisé (https)"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de projet et suivi d'avancement"])},
      "sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan du site convivial de Google"])}
    }
  },
  "contact": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veux-tu me parler ?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le mot de sécurité"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire"])},
    "emailFormatErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un email valide"])},
    "lengthErr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Longueur de caractères non valide, min. requis. ", _interpolate(_named("longueur")), " caractères"])},
    "phoneErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone est incorrect"])},
    "sendMailErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail non envoyé, essayez plus tard !"])},
    "sendMailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail envoyé avec succès!"])}
  },
  "pageNotFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong way?"])},
    "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reto"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type what you see here"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "emailFormatErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
    "lengthErr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid character length, required min. ", _interpolate(_named("length")), " characters"])},
    "phoneErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number is not correct"])},
    "sendMailErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not send, try later!"])},
    "sendMailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent successfully!"])}
  }
}