<template>
  <div class="hero-container">
    <div class="hero-text-box">
      <div class="hero-text">{{ $t(`hero.wedo`) }} <span>WEB DESIGN</span></div>
      <div class="buttons-box"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hero-component",
};
</script>
