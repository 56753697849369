export default {
  "appbar": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "portfolioGr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design"])},
    "portfolioUi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design web (UI)"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr"])}
  },
  "hero": {
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "wedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do"])},
    "wedoOptions": [
      {
        "option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web design"])}
      },
      {
        "option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web programming"])}
      },
      {
        "option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design"])}
      },
      {
        "option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photography"])}
      },
      {
        "option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX and SEO"])}
      }
    ]
  },
  "about": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT YOU NEED TO KNOW ABOUT US!"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHO WE ARE"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sharp group of 4 passionate people who are passionate about web development and design. A Frontend specialist, a Backend specilist, a UI/UX and graphic designer and a webmaster. This is MontrealWeb group. Talented and specialist!"])}
  },
  "services": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CE QUE VOUS DEVEZ SAVOIR SUR MOI !"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "ui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web design (UI)"])},
    "uiDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web design description"])},
    "programming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming"])},
    "programmingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming description"])},
    "ux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User experience"])},
    "uxDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UX, SEO description"])},
    "graphic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grasphic design"])},
    "graphicDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design description"])}
  },
  "portfolio": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See some projects and designs"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "gotoGraphicPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our Graphic design Porfolio page"])},
    "gotoUiPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our Web design Porfolio page"])},
    "graphicSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design"])},
    "uiSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web design (UI)"])},
    "uiSections": {
      "massage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massage"])},
      "sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
      "construction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
      "advocate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advocate"])},
      "medic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medicines and therapists"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residences"])},
      "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurent"])},
      "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small businesses"])},
      "kindergarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergarten"])},
      "beauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty salon"])}
    }
  },
  "packages": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See some projects and designs"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small size Business"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business level"])},
    "price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["From ", _interpolate(_named("price")), " $"])},
    "packages_note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After 14days after official last website update."])},
    "packages_note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Basic and Startup packages, one year webhost is included, but privilege and access codes will be kept for MontrealWeb company."])},
    "includedService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included services"])},
    "packages_note3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Custom Pre-design and final graphical full-color Design, High End UI Design, Custom Coding and Development, Multi Lingual, Custom Dynamic Contact Form, Complete Database Creation, integration, Search Engine Submission"])},
    "includes": {
      "pageCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Website inlude ", _interpolate(_named("page")), " Page and multiple section"])},
      "contactForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact form"])},
      "revisionDesign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("no")), " Revision"])},
      "responsive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsive design"])},
      "seo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEO friendly"])},
      "optimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image optimization"])},
      "satisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satisfaction Guarantee"])},
      "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 year free Host"])},
      "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure website (https)"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project management and progress tracking"])},
      "sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google friendly sitemap"])}
    }
  },
  "contact": {
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to talk to me?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type what you see here"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "emailFormatErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])},
    "lengthErr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid character length, required min. ", _interpolate(_named("length")), " characters"])},
    "phoneErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number is not correct"])},
    "sendMailErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not send, try later!"])},
    "sendMailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent successfully!"])}
  },
  "pageNotFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was a typo or wrong url?"])},
    "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "graphic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic Design portfolio"])},
    "ui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI design portfolio"])}
  }
}